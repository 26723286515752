<template>
  <div class="vol-page">
    <div class="vol-linear"></div>
    <div class="container">
      <div class="vol-banner">
        <span>找组织</span>
        <img src="../../assets/volunteer/hearts.png" class="vol-hearts" />
      </div>
      <div class="content-wrap">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/home">首页</a></li>
            <li class="breadcrumb-item active" aria-current="page">找组织</li>
          </ol>
        </nav>

        <div class="tool-top">
          <div class="filter-box">
            <select
              class="form-select"
              :value="selArea"
              aria-label="Default select example"
              @change="toChangeArea"
            >
              <option value="" selected>按地区</option>
              <option
                :value="item.area_id"
                v-for="item in areaList"
                :key="item.area_id"
              >
                {{ item.area_name }}
              </option>
            </select>
            <select
              class="form-select"
              aria-label="Default select example"
              @change="toChangeService"
            >
              <option value="" selected>按服务类别</option>
              <option
                :value="item"
                v-for="(item, index) in serviceList"
                :key="index"
              >
                {{ item }}
              </option>
            </select>
          </div>
          <SearchBar @toSearch="toSearch"></SearchBar>
        </div>
        <div class="vol-org-list">
          <div
            class="vol-org-item"
            v-for="item in data.list"
            :key="item.volunteer_group_id"
            @click="toOrgDetail(item)"
          >
            <div class="vol-org-info">
              <img :src="item.logo" class="org-logo" />
              <div class="vol-org-intro">
                <div class="vol-org-name">{{ item.orga_name }}</div>
                <div class="vol-org-des">
                  {{ decodeContent(item.intro) }}
                </div>
              </div>
            </div>
            <!-- toEventDetail -->
            <!-- 组织最新的一个活动 链接到活动详情-->
            <!-- @click=" toEventDetail()" v-for="item in event.list" :key="item.event_id" -->
            <div
              class="new-event"
              v-for="event in item.event_list"
              :key="event.event_id"
              @click.stop="toEventDetal(event)"
            >
              <img src="../../assets/volunteer/icon-new.svg" class="icon-new" />
              <div class="new-event-title">
                {{ event.title }}
              </div>
              <div class="new-event-des">
                已报名 {{ event.apply_count }}人
                <i class="iconfont icon-youjiantou"></i>
              </div>
            </div>
            <!-- <div class="vol-join-wrap"
                 bindtap="toOrgLogin">
              <div class="vol-org-join">
                <i class="iconfont icon-zengjia"></i>加入组织
              </div>
            </div> -->
          </div>
          <div class="none-data" v-if="!data.list || data.list.length == 0">
            <img src="../../assets/none.png" class="img-none" />
          </div>
        </div>

        <Pagination
          @change="pageChanged"
          :current="1"
          :pageSize="24"
          :hideOnSinglePage="true"
          :total="parseInt(data.total)"
          :showQuickJumper="false"
          :showTotal="false"
          placement="center"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { colunteerList, getBaseData, areaList } from "@/network/API.js";
import SearchBar from "@/components/SearchBar.vue";
import Pagination from "@/components/Pagination.vue";
export default {
  components: {
    SearchBar,
    Pagination,
  },
  data() {
    return {
      data: {},
      pageNumber: 1,
      searchKey: "",
      selService: "",
      selArea: {},
      serviceList: [],
      areaList: [],
    };
  },
  created() {
    this.getServiceList();
    this.getAreaList();
    this.getColunteerList();
  },
  methods: {
    getUserPosition() {
      const url =
        "https://apis.map.qq.com/ws/location/v1/ip?key=BINBZ-EKWHN-XMFF7-SNNTX-GFG6H-EZFYD";
      this.$jsonp(url, {
        output: "jsonp",
      }).then((res) => {
        let adInfo = res.result.ad_info;
        let area = this.areaList.filter((item) => {
          return item.area_name == adInfo.city;
        })[0];
        if (area) {
          this.selArea = area.area_id;
        }
        this.getColunteerList();
      });
    },
    toEventDetal(item) {
      this.$router.push({
        name: "EventDetail",
        params: {
          type: 3,
          id: item.event_id,
        },
      });
    },
    async getServiceList() {
      let data = await getBaseData("service_type");
      this.serviceList = data;
    },
    async getAreaList() {
      let data = await areaList({
        code: "130000",
      });
      if (this.type == 2) {
        data.splice(0, 0, {
          area_id: "130000",
          area_name: "推荐",
        });
        this.curArea = data[0];
      }
      this.areaList = data;
      this.getUserPosition();
    },
    async getColunteerList() {
      let data = await colunteerList({
        page_size: 24,
        page: this.pageNumber,
        keyword: this.searchKey,
        industry: this.selService,
        city_code: this.selArea,
      });
      this.data = data;
    },
    toSearch(val) {
      this.searchKey = val;
      this.pageNumber = 1;
      this.getColunteerList();
    },
    pageChanged(val) {
      this.pageNumber = val.page;
      this.getColunteerList();
    },
    toChangeService(e) {
      if (this.selService == e.target.value) return;
      this.selService = e.target.value;
      this.getColunteerList();
    },
    toChangeArea(e) {
      if (this.selArea == e.target.value) return;
      this.selArea = e.target.value;
      this.getColunteerList();
    },
    toOrgDetail(item) {
      this.$router.push({
        name: "OrgDetail",
        params: {
          id: item.volunteer_group_id,
        },
      });
    },
  },
};
</script>
<style>
.vol-banner {
  height: 280px;
}

.vol-hearts {
  top: -210px;
}

.tool-top {
  position: relative;
  height: 42px;
}

.search-box {
  top: 0;
}

.search {
  background: linear-gradient(
    96deg,
    rgba(255, 185, 144, 0.1) 0%,
    rgba(255, 50, 65, 0.1) 100%
  );
}

.vol-org-list {
  margin-top: 30px;
}
</style>
